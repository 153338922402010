import { Box, Typography } from "@enerbit/base";
import { ContainerCardRowConceptsTitle } from "../../styles/CardComponent";

export const ReSettlementCardRowConceptsTitle = () => {
  return (
    <ContainerCardRowConceptsTitle>
      <Box
        style={{ width: "40%", gap: "0.5rem", padding: "0 1rem" }}
        sx={{ display: "flex" }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
          Detalle
        </Typography>
      </Box>
      <Box
        style={{ width: "20%" }}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
          Factura inicial
        </Typography>
      </Box>
      <Box
        style={{ width: "20%" }}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
          Reliquidación
        </Typography>
      </Box>
      <Box
        style={{ width: "20%" }}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
          Diferencia
        </Typography>
      </Box>
    </ContainerCardRowConceptsTitle>
  );
};
