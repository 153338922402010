import {
    Box,
    FlashOnOutlinedIcon,
    formatterPeso,
    Icon,
    IconButton,
    KeyboardArrowDownIcon,
    KeyboardArrowUpIcon,
    Typography,
} from "@enerbit/base";
import { SettlementCardRowProps } from "./ReSettlementCardRowConcepts";
import {
    ContainerCardRowTotalHeader,
    ContainerTitleAndIcon,
} from "../../styles/CardComponent";
import { useState } from "react";

export const ReSettlementCardRowTotalHeader = ({
    title,
    fontWeight = 700,
    backgroundColor,
    color,
    minuend,
}: SettlementCardRowProps) => {
    const [displayTariff, setDisplayTariff] = useState(true);

    return (
        <ContainerCardRowTotalHeader>
            <ContainerTitleAndIcon>
                <Icon
                    sx={{
                        background: backgroundColor,
                        minWidth: "30px",
                        borderRadius: "5px",
                    }}
                >
                    <FlashOnOutlinedIcon
                        fontSize="small"
                        sx={{ color: "white" }}
                    />
                </Icon>
                <Typography sx={{ fontSize: "16px", fontWeight, color: color }}>
                    {title}
                </Typography>
            </ContainerTitleAndIcon>
            <Box
                style={{ width: "40%" }}
                sx={{ display: "flex", justifyContent: "flex-end" }}
            >
                <Typography sx={{ fontSize: "16px", fontWeight, color }}>
                    {minuend || minuend == 0
                        ? formatterPeso.format(minuend as number)
                        : ""}
                </Typography>
            </Box>

            <IconButton size="small" sx={{ color: "white" }}>
                {displayTariff ? (
                    <KeyboardArrowDownIcon
                        sx={{ color: "var(--color-primary)" }}
                        onClick={() => setDisplayTariff(!displayTariff)}
                    />
                ) : (
                    <KeyboardArrowUpIcon
                        sx={{ color: "var(--color-primary)" }}
                        onClick={() => setDisplayTariff(!displayTariff)}
                    />
                )}
            </IconButton>
        </ContainerCardRowTotalHeader>
    );
};
