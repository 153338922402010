import {
  Box,
  Typography,
  detailBillingNames,
  formatterPeso,
} from "@enerbit/base";
import { ContainerCardRowConcepts } from "../../styles/CardComponent";

export interface SettlementCardRowProps {
  title: string;
  color?: string;
  backgroundColor?: string;
  fontWeight?: number;
  minuend?: number;
  subtrahend?: number;
  difference?: number;
  showReactiveTable?: boolean;
  mMultiplier?: boolean;
}

export const ReSettlementCardRowConcepts = ({
  title,
  minuend,
  subtrahend,
  difference,
  showReactiveTable,
  mMultiplier,
}: SettlementCardRowProps) => {
  return (
    <ContainerCardRowConcepts>
      <Box
        style={{
          width: "40%",
          paddingTop: "10px",
          paddingLeft: "10px",
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography sx={{ fontSize: "14px", color: "#667085" }}>
          {showReactiveTable ? title : detailBillingNames[title] ?? title}
        </Typography>
      </Box>
      <Box
        style={{ width: "20%" }}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography sx={{ color: "#667085" }}>
          {title === "Consumo"
            ? subtrahend + " kWh"
            : mMultiplier
            ? subtrahend
            : formatterPeso.format(subtrahend ?? 0)}
        </Typography>
      </Box>
      <Box
        style={{ width: "20%" }}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            marginLeft: "1rem",
            color: "#667085",
          }}
        >
          {title === "Consumo"
            ? minuend + " kWh"
            : mMultiplier
            ? minuend
            : formatterPeso.format(minuend ?? 0)}
        </Typography>
      </Box>

      <Box
        style={{ width: "20%" }}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            color: difference! < 0 ? "#007F61" : "#D53C32",
            fontWeight: "700",
            marginLeft: "1rem",
          }}
        >
          {title === "Consumo"
            ? Math.ceil(difference ?? 0) + " kWh"
            : mMultiplier
            ? Math.ceil(difference ?? 0)
            : formatterPeso.format(difference ?? 0)}
        </Typography>
      </Box>
    </ContainerCardRowConcepts>
  );
};
