import { Box, enerbitColors, styled } from "@enerbit/base";
import {
    CardBody,
    CardContainer,
    CardHeader,
} from "../../styles/CardComponent";
import { ReSettlementCardRowTotalHeader } from "./ReSettlementCardRowTotalHeader";
import { ReSettlementCardRowConceptsTitle } from "./ReSettlementCardRowConceptsTitle";
import { ReSettlementCardRowConcepts } from "./ReSettlementCardRowConcepts";
import { ReSettlementCardRowTotalFooter } from "./ReSettlementCardRowTotalFooter";
import { useInvoiceMemoStore } from "../../../../../store/store";

const InvoiceMemoReactiveEnergyCard = () => {
    const { invoiceMemo } = useInvoiceMemoStore();

    return (
        <Box>
            {true && (
                <>
                    <CardContainer>
                        <CardHeader
                            sx={{
                                background: enerbitColors.primary[100],
                                padding: "0.2rem 0rem !important",
                                borderRadius: "10px 10px 0 0",
                            }}
                        >
                            <ReSettlementCardRowTotalHeader
                                backgroundColor={"var(--color-secondary)"}
                                title="Penalización energía reactiva"
                                fontWeight={700}
                                color={"var(--color-primary)"}
                                minuend={
                                    invoiceMemo.reactive_details?.total_amount
                                        ?.delta_total_amount ?? 0
                                }
                                mMultiplier={false}
                            />
                        </CardHeader>

                        <CardBody>
                            <ReSettlementCardRowConceptsTitle />
                            <ReSettlementCardRowConcepts
                                title="Exportada penalizable"
                                minuend={
                                    invoiceMemo.reactive_details
                                        ?.reactive_amount
                                        ?.resettlement_exported_reactive_amount ??
                                    0
                                }
                                subtrahend={
                                    invoiceMemo.reactive_details
                                        ?.reactive_amount
                                        ?.invoice_exported_reactive_amount ?? 0
                                }
                                difference={
                                    invoiceMemo.reactive_details
                                        ?.reactive_amount
                                        ?.delta_exported_reactive_amount
                                }
                                showReactiveTable={true}
                                mMultiplier={false}
                            />
                            <ReSettlementCardRowConcepts
                                title="Importada penalizable"
                                minuend={
                                    invoiceMemo.reactive_details
                                        ?.reactive_amount
                                        ?.resettlement_imported_reactive_amount ??
                                    0
                                }
                                subtrahend={
                                    invoiceMemo.reactive_details
                                        ?.reactive_amount
                                        ?.invoice_imported_reactive_amount ?? 0
                                }
                                difference={
                                    invoiceMemo.reactive_details
                                        ?.reactive_amount
                                        ?.delta_imported_reactive_amount
                                }
                                showReactiveTable={true}
                                mMultiplier={false}
                            />
                            <ReSettlementCardRowConcepts
                                title="Componente D"
                                minuend={
                                    invoiceMemo.reactive_details?.d_component
                                        ?.resettlement_d
                                }
                                subtrahend={
                                    invoiceMemo.reactive_details?.d_component
                                        ?.initial_invoice_d
                                }
                                difference={
                                    invoiceMemo.reactive_details?.d_component
                                        ?.delta_d
                                }
                                showReactiveTable={true}
                                mMultiplier={false}
                            />
                            <ReSettlementCardRowConcepts
                                title="Multiplicador M"
                                minuend={
                                    invoiceMemo.reactive_details?.m_multiplier
                                        ?.initial_invoice_m
                                }
                                subtrahend={
                                    invoiceMemo.reactive_details?.m_multiplier
                                        ?.resettlement_m
                                }
                                difference={
                                    (invoiceMemo.reactive_details?.m_multiplier
                                        ?.initial_invoice_m ?? 0) -
                                    (invoiceMemo.reactive_details?.m_multiplier
                                        ?.resettlement_m ?? 0)
                                }
                                showReactiveTable={true}
                                mMultiplier={true}
                            />
                        </CardBody>
                        <CardFooter>
                            <ReSettlementCardRowTotalFooter
                                title="Total penalización reactiva"
                                minuend={
                                    invoiceMemo.reactive_details
                                        ?.reactive_amount
                                        ?.resettlement_imported_reactive_amount! +
                                    invoiceMemo.reactive_details
                                        ?.reactive_amount
                                        ?.resettlement_exported_reactive_amount!
                                }
                                subtrahend={
                                    invoiceMemo.reactive_details
                                        ?.reactive_amount
                                        ?.invoice_imported_reactive_amount! +
                                    invoiceMemo.reactive_details
                                        ?.reactive_amount
                                        ?.invoice_exported_reactive_amount!
                                }
                                difference={
                                    invoiceMemo.reactive_details
                                        ?.reactive_amount
                                        ?.delta_exported_reactive_amount! +
                                    invoiceMemo.reactive_details
                                        ?.reactive_amount
                                        ?.delta_imported_reactive_amount!
                                }
                            />
                        </CardFooter>
                    </CardContainer>
                </>
            )}
        </Box>
    );
};
export default InvoiceMemoReactiveEnergyCard;

export const CardFooter = styled("div")`
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 1rem 1rem;
  min-height: 2rem;
  align-items: center;
  border: 1px solid #e4e7eb;
  background: transparent;
  color: var(--color-neutral700);
`;
