import { Box, formatterPeso, Typography } from "@enerbit/base";
import { SettlementCardRowProps } from "./ReSettlementCardRowConcepts";
import {
    ContainerCardRowTotalFooter,
    ContainerTitle,
} from "../../styles/CardComponent";

export const ReSettlementCardRowTotalFooter = ({
    title,
    fontWeight = 700,
    minuend,
    subtrahend,
    difference,
}: SettlementCardRowProps) => {
    return (
        <ContainerCardRowTotalFooter>
            <ContainerTitle>
                <Typography sx={{ fontSize: "14px", fontWeight }}>
                    {title}
                </Typography>
            </ContainerTitle>
            <Box
                style={{ width: "20%" }}
                sx={{ display: "flex", flexDirection: "column" }}
            >
                <Typography sx={{ fontWeight }}>
                    {subtrahend || subtrahend == 0
                        ? formatterPeso.format(subtrahend as number)
                        : ""}
                </Typography>
            </Box>
            <Box
                style={{ width: "20%" }}
                sx={{ display: "flex", flexDirection: "column" }}
            >
                <Typography sx={{ fontWeight }}>
                    {minuend || minuend == 0
                        ? formatterPeso.format(minuend as number)
                        : ""}
                </Typography>
            </Box>
            <Box
                style={{ width: "20%" }}
                sx={{ display: "flex", flexDirection: "column" }}
            >
                <Typography
                    sx={{
                        color: difference! < 0 ? "#007F61" : "#D53C32",
                        fontWeight: "700",
                        marginLeft: "1rem",
                    }}
                >{`${formatterPeso.format(difference as number)}`}</Typography>
            </Box>
        </ContainerCardRowTotalFooter>
    );
};
