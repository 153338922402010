import { Box, ServiceTypeNames } from "@enerbit/base";
import { useInvoiceMemoStore } from "../../../../store/store";
import { EnerbitInvoiceMemoAgreement } from "./EnerbitInvoiceMemoAgreement";
import { EssInvoiceMemoAgreement } from "./EssInvoiceMemoAgreement";

export const DetailInvoiceMemoAgreement = () => {
  const { invoiceMemoTypeName: invoiceTypeName } = useInvoiceMemoStore(
    (state) => ({
      invoiceMemoTypeName: state.invoiceMemoTypeName,
    })
  );

  return (
    <>
      {invoiceTypeName == ServiceTypeNames.electricitySupplyServices && (
        <Box>
          <EssInvoiceMemoAgreement />
        </Box>
      )}
      {invoiceTypeName == ServiceTypeNames.enerbitElectricitySupplyServices && (
        <Box>
          <EnerbitInvoiceMemoAgreement />
        </Box>
      )}
    </>
  );
};
