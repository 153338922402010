import { Box, ServiceTypeNames } from "@enerbit/base";
import { useInvoiceMemoStore } from "../../../store/store";
import { InformationInterest } from "./ContentDetail/EnerbitEssMemo/InformationInterest";
import InvoiceMemoDetailCard from "./ContentDetail/EssMemo/InvoiceMemoDetailCard";
import InvoiceMemoReactiveEnergyCard from "./ContentDetail/EssMemo/InvoiceMemoReactiveEnergyCard";

export const Content = () => {
    const { invoiceMemoTypeName: invoiceTypeName, memoHaveReactive } =
        useInvoiceMemoStore();

    return (
        <>
            <Box
                sx={{ borderTop: "1px solid #A3A9B6", marginBottom: "1.5rem" }}
            />
            <Box sx={{ mb: "2rem" }}>
                <InvoiceMemoDetailCard />
            </Box>

            <Box
                display="flex"
                gap="2rem"
                sx={{
                    "@media (max-width: 1020px)": {
                        flexDirection: "column",
                    },
                }}
            >
                {invoiceTypeName ===
                    ServiceTypeNames.electricitySupplyServices &&
                    memoHaveReactive && (
                        <Box width="100%">
                            <InvoiceMemoReactiveEnergyCard />
                        </Box>
                    )}

                {invoiceTypeName ===
                    ServiceTypeNames.enerbitElectricitySupplyServices && (
                    <Box width="100%">
                        <InformationInterest />
                    </Box>
                )}
            </Box>
        </>
    );
};
