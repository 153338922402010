import {
  Typography,
  capitalizeFirstLetter,
  enerbitColors,
} from "@enerbit/base";
import moment from "moment";
import { useInvoiceMemoStore } from "../../../../store/store";

export const DetailInvoiceMemoUsagesDate = () => {
  const { invoiceMemo } = useInvoiceMemoStore((state) => ({
    invoiceMemo: state.invoiceMemo,
  }));
  return (
    <>
      <Typography>
        <span style={{ color: enerbitColors.primary.main, fontWeight: "700" }}>
          Fecha emisión{" "}
          {(invoiceMemo.invoice_delta?.memo_amount ?? 0) < 0
            ? "nota crédito"
            : "nota débito"}
          :{" "}
        </span>
        <span style={{ color: enerbitColors.primary.main }}>
          {moment(invoiceMemo.memo_issue_date).format("DD") +
            " de " +
            `${capitalizeFirstLetter(
              moment(invoiceMemo.memo_issue_date).format("MMMM")
            )}` +
            " del " +
            moment(invoiceMemo.memo_issue_date).format("YYYY")}
        </span>
      </Typography>
      <Typography>
        <span style={{ color: enerbitColors.primary.main, fontWeight: "700" }}>
          Fecha factura padre:{" "}
        </span>
        <span style={{ color: enerbitColors.primary.main }}>
          {moment(invoiceMemo.parent_invoice_issue_date).format("DD") +
            " de " +
            `${capitalizeFirstLetter(
              moment(invoiceMemo.parent_invoice_issue_date).format("MMMM")
            )}` +
            " del " +
            moment(invoiceMemo.parent_invoice_issue_date).format("YYYY")}
        </span>
      </Typography>
      <Typography>
        <span style={{ color: enerbitColors.primary.main, fontWeight: "700" }}>
          Consumos:{" "}
        </span>
        <span style={{ color: enerbitColors.primary.main }}>
          {`${moment(invoiceMemo.metering_since).format(
            "DD"
          )} ${capitalizeFirstLetter(
            moment(invoiceMemo.metering_since).format("MMMM")
          )} de ${moment(invoiceMemo.metering_since).format(
            "YYYY"
          )} al ${moment(invoiceMemo.metering_until).format(
            "DD"
          )} ${capitalizeFirstLetter(
            moment(invoiceMemo.metering_until).format("MMMM")
          )} de ${moment(invoiceMemo.metering_until).format("YYYY")}`}{" "}
        </span>
      </Typography>
    </>
  );
};
