import { Box, Typography, enerbitColors, theme } from "@enerbit/base";
import { useInvoiceMemoStore } from "../../../../store/store";

export const EnerbitInvoiceMemoAgreement = () => {
  const { invoiceMemo } = useInvoiceMemoStore((state) => ({
    invoiceMemo: state.invoiceMemo,
  }));

  function getValueFromDictionary(key: string): string | undefined {
    const dictionary: { [key: string]: string } = {
      meter_rent: "Alquiler medidor",
      tech_tools: "Fijo",
    };

    return dictionary[key];
  }

  return (
    <Box sx={{ marginRight: "3.5rem" }}>
      <Box sx={{ display: "flex" }}>
        {invoiceMemo.service_agreement?.description ? (
          <Typography
            color={theme.palette.primary.main}
            sx={{ fontSize: "14px", mr: "0.3rem" }}
          >
            <span
              style={{ color: enerbitColors.primary.main, fontWeight: "700" }}
            >
              Plan:{" "}
            </span>
            <span style={{ color: enerbitColors.primary.main }}>
              {invoiceMemo.service_agreement?.name}
            </span>
          </Typography>
        ) : (
          <Box></Box>
        )}
      </Box>
      {invoiceMemo.service_agreement?.conditions?.charges?.map((charge) =>
        charge.conditions?.intervals?.map((interval) => (
          <Box sx={{ display: "flex" }} key={interval.price}>
            <Typography
              color={theme.palette.primary.main}
              sx={{ fontSize: "14px", mr: "0.3rem" }}
            >
              <span
                style={{ color: enerbitColors.primary.main, fontWeight: "700" }}
              >
                G+C:{" "}
              </span>
              <span style={{ color: enerbitColors.primary.main }}>
                {interval.price}
              </span>
            </Typography>
          </Box>
        ))
      )}
      {invoiceMemo.service_agreement?.conditions?.charges?.map((charge) =>
        charge.conditions?.components?.map((component) => (
          <Box sx={{ display: "flex" }} key={component.name}>
            <Typography
              color={theme.palette.primary.main}
              sx={{ fontSize: "14px", mr: "0.3rem" }}
            >
              <span
                style={{ color: enerbitColors.primary.main, fontWeight: "700" }}
              >
                {getValueFromDictionary(component.name ?? "")}:{" "}
              </span>
              <span style={{ color: enerbitColors.primary.main }}>
                {component.price}
              </span>
            </Typography>
          </Box>
        ))
      )}
    </Box>
  );
};
