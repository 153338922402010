import { Box, Typography, styled } from "@enerbit/base";
import {
	CardBody,
	CardBodyTitle,
	CardHeader,
	CardReSettlementContainer,
} from "../../styles/CardComponent";
import { ReSettlementCardRowConcepts } from "./ReSettlementCardRowConcepts";
import { ContainerHeaderCards } from "../common/ContainerHeaderCards";
import { useInvoiceMemoStore } from "../../../../../store/store";

const InvoiceMemoDetailCard = () => {
	const { nabla } = useInvoiceMemoStore((state) => ({
		invoiceMemo: state.invoiceMemo,
		nabla: state.nabla,
	}));
	return (
		<Box>
			{true && (
				<>
					<CardReSettlementContainer>
						<CardHeader>
							<Box sx={{ mb: "1rem" }}>
								<ContainerHeaderCards />
							</Box>
						</CardHeader>

						<CardBody>
							<CardBodyTitle>
								<Box style={{ width: "20%" }}>
									<Typography sx={{ fontWeight: "700", color: "#4A2F7E" }}>
										Concepto
									</Typography>
								</Box>
							</CardBodyTitle>
							{nabla.map((item, _index) =>
								item.difference !== 0 ? (
									<ReSettlementCardRowConcepts
										key={item.component_code}
										title={item.name as string}
										minuend={item.resettlement}
										subtrahend={item.invoice_initial_amount}
										difference={item.difference}
										showReactiveTable={false}
									/>
								) : (
									<Box key={item.component_code} />
								),
							) ?? null}
						</CardBody>
					</CardReSettlementContainer>
				</>
			)}
		</Box>
	);
};
export default InvoiceMemoDetailCard;

export const CardFooter = styled("div")`
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 1rem 1rem;
  min-height: 2rem;
  align-items: center;
  border: 1px solid #e4e7eb;
  background: transparent;
  color: var(--color-neutral700);
`;
