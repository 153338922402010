import {
	Box,
	CircularProgress,
	Dialog,
	DialogContent,
	theme,
	Typography,
} from "@enerbit/base";
import { Header } from "./components/Header";
import { useEffect } from "react";
import { DetailInvoiceMemoAgreement } from "./components/HeaderDetails/DetailInvoiceMemoAgreement";
import { DetailInvoiceMemoUsagesDate } from "./components/HeaderDetails/DetailInvoiceMemoUsagesDate";
import { Content } from "./components/Content";
import { useInvoiceMemoStore } from "../../store/store";

const InvoiceMemoDialog = () => {
	const {
		setOpenHistoryDialog,
		openHistoryDialog,
		isLoadingInvoiceMemoRequest,
		invoiceMemoName,
	} = useInvoiceMemoStore();

	useEffect(() => {
		setOpenHistoryDialog(true);
	}, [setOpenHistoryDialog]);

	return (
		<Dialog
			open={openHistoryDialog}
			fullWidth={true}
			maxWidth={"lg"}
			PaperProps={{
				sx: {
					p: "25px 30px",
					borderRadius: "20px",
					boxShadow: "none",
				},
			}}
		>
			{isLoadingInvoiceMemoRequest ? (
				<>
					<Box
						display="flex"
						gap="1rem"
						sx={{ alignItems: "center", flexDirection: "column" }}
					>
						<Typography
							fontSize={26}
							fontWeight={"bold"}
							color={theme.palette.primary.main}
						>
							Cargando detalle
						</Typography>
						<CircularProgress />
					</Box>
				</>
			) : (
				<>
					<Header
						title={invoiceMemoName}
						detailRight={<DetailInvoiceMemoAgreement />}
						detailLeft={<DetailInvoiceMemoUsagesDate />}
					/>
					<DialogContent sx={{ p: "25px 0" }}>
						<Content />
					</DialogContent>
				</>
			)}
		</Dialog>
	);
};

export default InvoiceMemoDialog;
