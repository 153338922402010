import {
  Box,
  ChatOutlinedIcon,
  Grid,
  MonetizationOnOutlinedIcon,
  Typography,
  enerbitColors,
} from "@enerbit/base";

export const InformationInterest = () => {
  return (
    <Grid container>
      <Grid xs={12} md={6}>
        <Box className="Card-data-interest-user-container" sx={{ mr: "10px" }}>
          <Box
            sx={{
              backgroundColor: enerbitColors.primary.main, // color de fondo
              borderRadius: "50%", // hace que el Box sea redondo
              display: "flex", // centra el icono horizontalmente
              justifyContent: "center", // centra el icono horizontalmente
              alignItems: "center", // centra el icono verticalmente
              width: "40px", // ancho del Box
              height: "40px", // altura del Box
              padding: "30px", // padding del Box
              marginRight: "20px", // margen derecho del Box
            }}
          >
            <ChatOutlinedIcon sx={{ color: "white", fontSize: "2rem" }} />
          </Box>
          <Box>
            <Typography
              sx={{
                color: enerbitColors.primary.main,
                fontSize: "18px",
                fontWeight: "700",
              }}
            >
              Datos que le pueden interesar al usuario
            </Typography>
            <Typography sx={{ color: enerbitColors.neutral.main }}>
              La cobertura te permite{" "}
              <span style={{ fontWeight: "700" }}>
                asegurar tu ahorro anual y la protección ante aumentos abruptos
                de tu tarifa de energía,
              </span>
              además podrás revisar tus estadísticas de consumo, programar
              alertas y monitorear en tiempo real.
            </Typography>
          </Box>
        </Box>
      </Grid>
      {/* <Grid xs={12} md={6}>
        <Box className="Card-data-interest-user-container" sx={{ ml: "10px" }}>
          <Box
            className="Card-data-interest-services-enerbit-icon"
            sx={{
              background: enerbitColors.success.main,
              padding: "1rem",
              borderRadius: "50%",
              marginRight: "10px",
            }}
          >
            <MonetizationOnOutlinedIcon
              sx={{ fontSize: "4rem", color: "white" }}
            />
          </Box>
          <Box>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    color: enerbitColors.primary.main,
                    fontSize: "22px",
                    fontWeight: "700",
                  }}
                >
                  Ahorros con la tarifa enerPro
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{
                    color: enerbitColors.success.main,
                    fontSize: "22px",
                    fontWeight: "700",
                  }}
                >
                  $ 23,000
                </Typography>
              </Grid>
            </Grid>
            <Typography sx={{ color: enerbitColors.neutral.main }}>
              Con <span style={{ fontWeight: "700" }}>enerPro</span> el usuario
              ha ahorrado a la fecha $ 23.000. Este ahorro corresponde del 01 de
              enero al 15 de febrero
            </Typography>
          </Box>
        </Box>
      </Grid> */}
    </Grid>
  );
};
