import { Box, DownloadOutlinedIcon, LoadingButton } from "@enerbit/base";
import { useInvoiceMemoStore } from "../../../../../store/store";

export interface ContainerCardAdjustProps {
	title: JSX.Element;
	value: JSX.Element;
	showButtonDownload: boolean;
	isMemo?: boolean;
}

export const ContainerCardAdjust = ({
	title,
	value,
	showButtonDownload,
	isMemo,
}: ContainerCardAdjustProps) => {
	const {
		isLoadingInvoiceMemoDocumentRequest,
		isLoadingInvoiceDocumentRequest,
		invoiceMemoId,
		invoiceMemo,
		getDocument,
	} = useInvoiceMemoStore();
	return (
		<>
			<Box>
				{title}
				{value}
			</Box>
			<Box
				sx={{
					marginLeft: "auto",
				}}
			>
				{showButtonDownload && (
					<LoadingButton
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						style={{ height: "50px", width: "50px", borderRadius: "12px" }}
						variant="contained"
						color="secondary"
						size="small"
						className="Button-download-memo"
						onClick={() => {
							isMemo
								? getDocument({
										documentId: invoiceMemoId,
										isMemo: true,
									})
								: getDocument({
										documentId: invoiceMemo.invoice_id ?? "",
										isMemo: false,
									});
						}}
						loading={
							isMemo
								? isLoadingInvoiceMemoDocumentRequest
								: isLoadingInvoiceDocumentRequest
						}
						startIcon={
							<DownloadOutlinedIcon
								sx={{
									fontSize: "1rem",
									marginRight: "0px",
									marginLeft: " 0px",
								}}
							/>
						}
					/>
				)}
			</Box>
		</>
	);
};
