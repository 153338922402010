import { Box, Typography, theme } from "@enerbit/base";
import { useInvoiceMemoStore } from "../../../../store/store";

export const EssInvoiceMemoAgreement = () => {
  const { invoiceMemo } = useInvoiceMemoStore((state) => ({
    invoiceMemo: state.invoiceMemo,
  }));

  return (
    <Box sx={{ marginRight: "3.5rem" }}>
      <Box sx={{ display: "flex", mr: "0.3rem" }}>
        {invoiceMemo.creg_subscriber?.voltage_level?.sui_code ? (
          <Typography
            color={theme.palette.primary.main}
            sx={{ fontWeight: "700", fontSize: "16px", mr: "0.3rem" }}
          >
            Nivel de tensión{" "}
            {invoiceMemo.creg_subscriber?.voltage_level?.sui_code}
          </Typography>
        ) : (
          <Box></Box>
        )}
      </Box>
      <Box sx={{ display: "flex" }}>
        {invoiceMemo.service_agreement?.description ? (
          <Typography
            color={theme.palette.primary.main}
            sx={{ fontWeight: "700", fontSize: "16px", mr: "0.3rem" }}
          >
            {invoiceMemo.service_agreement?.description}
          </Typography>
        ) : (
          <Box></Box>
        )}
      </Box>
    </Box>
  );
};
