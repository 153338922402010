import { ServiceTypeNames } from "@enerbit/base";
import { useEffect } from "react";
import { useInvoiceMemoStore } from "../store/store";
import InvoiceMemoDialog from "./InvoiceMemoDialog/InvoiceMemoDialog";

const BASE_PATH = "/electricity-supply-service/billing/";

export const GetDialogByInvoiceMemo = ({
  serviceTypeName,
  invoiceMemoId,
}: {
  serviceTypeName: ServiceTypeNames;
  invoiceMemoId: string;
}): JSX.Element => {
  const {
    setInvoiceTypeName,
    getMemoDetail,
    setInvoiceMemoName,
    setInvoiceMemoId,
  } = useInvoiceMemoStore();

  useEffect(() => {
    setInvoiceTypeName(serviceTypeName);
    setInvoiceMemoId(invoiceMemoId);
    if (serviceTypeName === ServiceTypeNames.electricitySupplyServices) {
      getMemoDetail({
        invoiceMemoId: invoiceMemoId,
        path: BASE_PATH + "electricity-supply/memo/",
      });
      setInvoiceMemoName("Detalle reliquidación de energía");
    }

    if (serviceTypeName === ServiceTypeNames.enerbitElectricitySupplyServices) {
      getMemoDetail({
        invoiceMemoId: invoiceMemoId,
        path: BASE_PATH + "enerbit-electricity-supply/memo/",
      });

      setInvoiceMemoName("Detalle reliquidación servicios enerBit");
    }
  }, [serviceTypeName, invoiceMemoId]);

  return <InvoiceMemoDialog />;
};
