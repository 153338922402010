import {
  Box,
  Button,
  DialogActions,
  Grid,
  HighlightOffOutlinedIcon,
  theme,
  Typography,
} from "@enerbit/base";
import { useInvoiceMemoStore } from "../../../store/store";

export const Header = ({
  title,
  detailLeft,
  detailRight,
}: {
  title: string;
  detailLeft: JSX.Element;
  detailRight: JSX.Element;
}) => {
  const setOpenHistoryDialog = useInvoiceMemoStore(
    (state) => state.setOpenHistoryDialog
  );
  return (
    <>
      <Box className="Container-history-dialog-billing">
        <Box>
          <Typography
            color={theme.palette.secondary.main}
            sx={{ fontSize: "28px", fontWeight: "700" }}
          >
            {title}
          </Typography>
        </Box>
        <DialogActions style={{ padding: "0px" }}>
          <Button
            onClick={() => setOpenHistoryDialog(false)}
            sx={{
              "& .MuiButtonBase-root": {
                borderRadius: "100%",
              },
            }}
          >
            <HighlightOffOutlinedIcon fontSize="large" />
          </Button>
        </DialogActions>
      </Box>
      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Grid item xs={12} md={6}>
          {detailLeft}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          {detailRight}
        </Grid>
      </Grid>
    </>
  );
};
